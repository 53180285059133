html, body {
	height: 100%;
	margin: 0;
  }
  
  #root {
	display: flex;
	flex-direction: column;
	min-height: 100vh; /* Make sure the body takes up at least the full viewport height */
  }

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    background-color: rgba(255, 255, 255, 0.517);
	min-height: 100%;
}

main {
	min-height: 100vh;
	height: 100%;
	flex: 1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	color: #FFC000;
	font-weight: bold;
}

h2 {
	color: #FFC000;
}

h3 {
	color: #FFC000;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("./images/hamburger.svg") !important;
}

.navbar-collapse .collapse .show {
	width: 25%;
}
.nav-link {
	color: #3E4F61 !important;
}

.nav-link:hover {
	color: #FFC000 !important;
}

.navbar-expand-lg {
	width: 100% !important;
}

.active {
	color: #FFC000 !important;
}
.socials {
	color: #FFC000 !important;
	margin-top: 0.7rem;
	margin-bottom: 0.8rem;
	margin-right: 0.3rem;
	font-size: 1.1rem;
}

.socials:first-of-type {
	margin-left: 1rem;
}

.socials:last-of-type {
	margin-right: 0rem;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFC000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFC000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-indicators li {
	background-color: #FFC000;
}

.page-item.active .page-link {
	background-color: #FFC000;
	color: #FFC000;
}

.page-link {
	background-color: #FFC000;
	color: #FFC000;
}

.page-link:hover {
	background-color: #FFC000;
	color: #FFC000;
}

.product-card {
	height: 100%;
	max-height: 500px;
}

.category-buttons {
	background-color: #fff;
	color: #FFC000;
	border-color: #fff;
}

.v-divider {
	margin-top: 5px;
	margin-bottom: 5px;
	height: 1px;
	width: 100%;
	border-top: 1px solid gray;
}

.dot-style button {
	border-color: #FFC000 !important;
}

.react-multi-carousel-dot--active button {
	background-color: #FFC000 !important;
}

.react-multiple-carousel__arrow {
	background-color: #FFC00079 !important;
}

.react-multiple-carousel__arrow:hover {
	background-color: #FFC000 !important;
}

.pagination-arrows {
	color: #FFC000;
	font-weight: bold;
	font-size: 0.8rem;
}

@media screen and (max-width: 992px) {
	.card {
		margin: 0 auto;
	}

	.category-container {
		margin-bottom: 3rem;
	}
}

@media screen and (max-width: 800px) {
	.cart-nav {
		margin-left: 0px !important;
	}

	.category-container {
		margin-bottom: 3rem;
	}

	.category-container h3 {
		font-size: 1rem;
		font-weight: bold;
	}

	.contact-dots {
		display: none !important;
	}

	.mobile-contact {
		display: flex;
		justify-content: center;
		padding-right: 10rem;
		padding-left: 10rem;
	}

	.back-btn {
		display: flex;
		justify-content: center;
		margin-left: 0 !important;
	}
}

@media screen and (max-width: 560px) {
	.mobile-navbar-padding {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.category-container {
		margin-bottom: 3rem;
		height: 15rem !important;
	}

	.contact-dots {
		display: none !important;
	}

	.mobile-contact {
		display: flex;
		justify-content: center;
		padding-left: 5rem;
		padding-right: 5rem;
	}

	.profile-orders {
		margin-top: 2rem !important;
	}
}
